import { useLocation } from "react-router-dom";
import SecondaryNavLink from "./SecondaryNavLink";
import { StyledSecondaryNav } from "./SecondaryNav.module";
import { navOptions } from "constants/navigation";
import { getPreviousPage } from "utils";

export interface SecondaryNavProps {
  // white?: boolean;
  // onNavItemClick?: () => void;
  type: "users" | "assets" | "assetDashboard" | "requests" | "packagingManager";
}

const SecondaryNav = ({ type }: SecondaryNavProps) => {
  const location = useLocation();
  
  
  const checkPageUrl = (url: string, title: string): boolean => {
    if (url === location.pathname) {
      return true;
    } else if (type === "assets") {
      if (title === "Restricted assets" && location.pathname.includes("manage-requests")) {
        return true;
      }
      return false;
    } else if (type === "requests") {
      const previousPage = getPreviousPage();
      if (previousPage) {
        if (location.pathname.includes('/assets/restricted-assets/request-details') && previousPage.includes('manage-requests') && url.includes("manage-requests")) {
          return true;
        } else if (location.pathname.includes('/assets/restricted-assets/request-details') && previousPage.includes('my-requests') && url.includes("my-requests")) {
          return true;
        }
      }
    } else if (type === "packagingManager") {
      if (title === "Dashboard" && !location.pathname.includes('reporting') && location.pathname.includes('packaging-manager')) {
        return true;
      } else if (title === "Reporting" && location.pathname.includes('reporting')) {
        return true;
      }
    }
    return false;
  };

  return (
    <StyledSecondaryNav>
      {navOptions[type].map((item) => {
        return (
          <SecondaryNavLink
            key={item.title}
            to={item.url ? item.url : ""}
            label={item.title}
            // onClick={handleNavItemClick}
            // white={white}
            active={checkPageUrl(item.url, item.title)}
          />
        );
      })}
    </StyledSecondaryNav>
  );
};

export default SecondaryNav;
