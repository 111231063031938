import paths from "routing/paths";

export const navOptions = {
  users: [
    {
      url: `${paths.ADMIN}${paths.USER_MANAGEMENT}`,
      title: "Users",
      active: false,
    },
    {
      url: `${paths.ADMIN}${paths.PENDING_USER_MANAGEMENT}`,
      title: "Pending users",
      active: false,
    },
    {
      url: `${paths.ADMIN}${paths.GROUP_MANAGEMENT}`,
      title: "Groups",
      active: false,
    },
  ],
  assets: [
    {
      url: `${paths.ASSETS}`,
      title: "Overview",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.MY_DOWNLOADS}`,
      title: "My downloads",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.MY_UPLOADS}`,
      title: "My uploads",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.MY_FAVORITES}`,
      title: "My favorites",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.SAVED_SEARCHES}`,
      title: "Saved searches",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MY_REQUESTS}`,
      title: "Restricted assets",
      active: false,
    },
  ],
  assetDashboard: [
    {
      url: `${paths.ASSETS}${paths.MY_DOWNLOADS}`,
      title: "My downloads",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.MY_UPLOADS}`,
      title: "My uploads",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.MY_FAVORITES}`,
      title: "My favorites",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.SAVED_SEARCHES}`,
      title: "Saved searches",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MY_REQUESTS}`,
      title: "Restricted assets",
      active: false,
    },
  ],
  requests: [
    {
      url: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MY_REQUESTS}`,
      title: "My requests",
      active: false,
    },
    {
      url: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MANAGE_REQUESTS}`,
      title: "Manage requests",
      active: false,
    },
  ],
  packagingManager: [
    {
      url: `${paths.PACKAGING_MANAGER_PROJECTS}`,
      title: "Dashboard",
      active: false,
    },
    {
      url: `${paths.PACKAGING_MANAGER_REPORTING}`,
      title: "Reporting",
      active: false,
    },
  ],
};
